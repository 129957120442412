<script setup>
import { reactive, computed, onMounted, watch } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const props = defineProps({
  checkboxDisabled: {
    type: Boolean,
    default: false,
  },
  showConsentModal: {
    type: Boolean,
    default: false,
  },
})

const ohBuyer = {
  name: 'OpenHome',
  ids: [60003]
}

const state = reactive({
  selectedBuyers: [],
  remoteBuyers: computed(() => {
    if (!store.state.oneToOneConsent.buyers) return []
    const buyers = store.state.oneToOneConsent.buyers.filter(buyer => buyer && buyer.name);
    // get only the first 9 buyers
    // return buyers.slice(0, 9)
    return buyers
  }),
  selectedBuyerNames: computed(() => {
    return state.selectedBuyers.map(selected => {
      if (JSON.stringify(selected) === JSON.stringify(ohBuyer.ids)) {
        return ohBuyer.name
      }
      const buyer = state.remoteBuyers.find(buyer => JSON.stringify(buyer.ids) === JSON.stringify(selected))
      return buyer.name
    })
  }),
  selectedBuyersNumber: computed(() => `${state.remoteBuyers.length + 1}/${state.selectedBuyers.length}`),
  selectedBuyersChanged: computed(() => state.remoteBuyers.length + 1 !== state.selectedBuyers.length ? 'yes' : 'no'),
  showFirstConsentModal: computed(() => store.state.oneToOneConsent.showFirstConsentModal),
  showSecondConsentModal: computed(() => store.state.oneToOneConsent.showSecondConsentModal),
})

onMounted(() => {
  state.selectedBuyers = [
    ohBuyer.ids,
    ...state.remoteBuyers.map(buyer => buyer.ids)
  ]
  const allAvailableBuyerNames = [
    ohBuyer.name,
    ...state.remoteBuyers.map(buyer => buyer.name)
  ]
  store.commit('SET_ALL_AVAILABLE_BUYER_NAMES', allAvailableBuyerNames)
})

// Watch for changes in selectedBuyers and commit to store
watch(
  () => state.selectedBuyers,
  (newSelectedBuyers) => {
    store.commit('SET_SELECTED_BUYERS', newSelectedBuyers)
    store.commit('SET_SELECTED_BUYERS_NUMBER', state.selectedBuyersNumber)
    store.commit('SET_SELECTED_BUYERS_CHANGED', state.selectedBuyersChanged)
  },
  { deep: true }
)

watch(
  () => state.selectedBuyerNames,
  (names) => {
    console.log('selectedBuyerNames', names)
    store.commit('SET_SELECTED_BUYER_NAMES', names)
  },
  { deep: true }
)

const toggleSelection = (buyerIds, name) => {
  const index = state.selectedBuyers.findIndex(
    selected => JSON.stringify(selected) === JSON.stringify(buyerIds)
  )
  if (index !== -1) {
    // If already selected, remove it
    state.selectedBuyers.splice(index, 1)
  } else {
    // If not selected, add it
    state.selectedBuyers.push(buyerIds)
  }
}

const handleSkipFirstModal = () => {
  store.commit('SET_SHOW_CONSENT_MODAL', { modal: 'showFirstConsentModal', value: false })
}

const handleSkipSecondModal = () => {
  store.commit('SET_SHOW_CONSENT_MODAL', { modal: 'showSecondConsentModal', value: false })
}

const handleAllowAll = () => {
  state.selectedBuyers = [
    ohBuyer.ids,
    ...state.remoteBuyers.map(buyer => buyer.ids)
  ]
  store.commit('SET_SHOW_CONSENT_MODAL', { modal: 'showFirstConsentModal', value: false })
}

const formatName = (name) => name.toLowerCase().replace(/\s+/g, "-");
</script>

<template>
  <div v-if="store.state.oneToOneConsent.showFirstConsentModal" class="consent-modal">
    <div class="box">
      <div class="close-button" @click="handleSkipFirstModal">x</div>
      <p>
        Hey, just a heads-up—only one or two firms will reach out, max!
        We're not here to flood your inbox or spam you.
        To make sure we match you with the best fit, please reconsider allowing all, as we won't know who can assist until you submit.
      </p>
      <div class="button-container">
        <button class="button" @click="handleAllowAll">Allow All</button>
        <button class="ignore-button" @click="handleSkipFirstModal">Skip</button>
      </div>
    </div>
  </div>
  <div v-if="store.state.oneToOneConsent.showSecondConsentModal" class="consent-modal">
    <div class="box">
      <div class="close-button" @click="handleSkipSecondModal">x</div>
      <p>
        In order to help you, we need you to select at least one firm, otherwise we will not be able to help you
      </p>
      <div class="button-container">
        <button class="button" @click="handleSkipSecondModal">Ok</button>
      </div>
    </div>
  </div>
  <span
    class="buyers-container"
  >
    <span class="buyers-check-box">
      <input
        type="checkbox"
        :checked="state.selectedBuyers.some(selected => JSON.stringify(selected) === JSON.stringify(ohBuyer.ids))"
        @change="toggleSelection(ohBuyer.ids, 'OpenHome')"
        id="oh"
        :disabled="checkboxDisabled"
        data-tf-element-role="consent-opted-advertiser-input-auto-accident-team"
      />
      <span
        v-if="state.selectedBuyers.some(selected => JSON.stringify(selected) === JSON.stringify(ohBuyer.ids))"
        @click="toggleSelection(ohBuyer.ids, 'OpenHome')"
        class="checkmark"
      >✓</span>
      <label data-tf-element-role="consent-opted-advertiser-name-auto-accident-team" style="margin-right: 0;" for="oh">OpenHome</label>
    </span>
    <span>
      and one of the following <span style="margin-right: 5px;">firms</span><span class="hide">:</span>
    </span>
    <span class="buyers-check-box" v-for="buyer in state.remoteBuyers" :key="buyer.name">
      <input
        type="checkbox"
        :checked="state.selectedBuyers.some(selected => JSON.stringify(selected) === JSON.stringify(buyer.ids))"
        @change="toggleSelection(buyer.ids, buyer.name)"
        :id="buyer.name"
        :disabled="checkboxDisabled"
        :data-tf-element-role="`consent-opted-advertiser-input-${formatName(buyer.name)}`"
      />
      <span
        v-if="state.selectedBuyers.some(selected => JSON.stringify(selected) === JSON.stringify(buyer.ids))"
        @click="toggleSelection(buyer.ids, buyer.name)"
        class="checkmark"
      >✓</span>
      <label
        :for="buyer.name"
        :data-tf-element-role="`consent-opted-advertiser-name-${formatName(buyer.name)}`"
      >
        {{ buyer.name }}<span style="color: transparent;">,</span>
      </label>
    </span>
  </span>
</template>

<style scoped lang="scss">
.hide {
  display: none;
}

.buyers-check-box {
  display: inline-flex;
  margin: 0;
  line-height: 18px;
  align-items: baseline;
  position: relative;
  input[type="checkbox"] {
    appearance: none;
    width: 14px;
    height: 14px;
    background-color: #fff;
    border: 1px solid #000000c4;
    border-radius: 5px;
    transition: background-color 0.2s, border-color 0.2s;
    cursor: pointer;
    margin: 0;
    position: relative;
    bottom: -1px;
  }

  input[type="checkbox"]:checked {
    background-color: #fff;
  }

  .checkmark {
    position: absolute;
    left: 2.5px;
    top: 0px;
    color: rgba(0, 0, 0, .769);
    font-size: 10px;
    font-weight: 700;
    display: block;
    cursor: pointer;
  }

  label {
    display: inline;
    margin-left: 3px;
    margin-right: 4px;
    font-size: 12pt;
    color: #000;
  }
  &:last-child {
    label {
      margin-right: -1px;
    }
  }
}

.consent-modal {
  position: absolute;
  background: #0000007a;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  .box {
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
    margin: 0 20px 420px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    .close-button {
      position: absolute;
      top: -12px;
      right: -10px;
      cursor: pointer;
      background: #be2026f7;
      width: 35px;
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      border-radius: 50px;
      font-size: 16px;
      padding-bottom: 2px;
      font-family: monospace;
    }
    p {
      color: #00283f;
      font-size: 22px;
      line-height: 1.4;
      padding-bottom: 20px;
      text-align: center;
      margin: 0;
    }
    .button-container{
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 20px;
      flex-wrap: wrap;
      .button {
        transition-duration: 0.3s;
        transition-property: all;
        border-radius: 50px;
        background-color: #be2026;
        border: 1px #000;
        color: #fff;
        direction: ltr;
        display: block;
        float: none;
        font-size: 18px;
        line-height: 20px;
        overflow: visible;
        padding: 15px 30px;
        position: static;
        text-align: center;
        text-decoration: none;
        text-transform: none;
        white-space: normal;
        margin: 0 auto;
        cursor: pointer;
        box-sizing: border-box;
        min-width: 125px;
      }
      .ignore-button {
        color: black;
        background: transparent;
        cursor: pointer;
        border: none;
        margin-bottom: 10px;
      }
    }
  }
}

.tcpa-container {
  margin: 10px;
  p {
    text-align: justify;
    font-size: 12pt;
    line-height: 18px;
    color: black;
    a {
      color: #18469d;
    }
  }
}

@media (max-width: 490px) {
  .tcpa-container {
    margin: 10px 0;
  }
}
</style>
